<template>
  <div class="text-center" v-if="resolutionScreen <= 500">
    <v-snackbar
      v-model="snackbar"
      :top="resolutionScreen < 500"
      :max-height="resolutionScreen"
      :timeout="-1"
    >
      <!-- :timeout="timeout" -->
      <v-card color="transparent" class="pa-0">
        <v-card-text class="pa-0">
          <!-- <v-list
            color="transparent"
            dark
            v-if="
              count_loadingfile === undefined && count_allfile === undefined
            "
          >
            <v-list-item>
              <v-list-item-content>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <v-list-item-title
                  >{{ namedowload }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="percent === 100" color="green"
                  >mdi-check-bold</v-icon
                >
                <div v-else class="text-center pt-1">
                  <a style="color:white;">{{ percent + "%" }}</a>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list> -->
          <v-list color="transparent" dark>
            <v-list-item>
              <v-list-item-content v-if="total === 100">
                {{ $t("myinboxPage.dowload.dowloadsuccess") }}
                <!-- <v-list-item-title>{{ namedowload | subStr}}</v-list-item-title> -->
              </v-list-item-content>
              <v-list-item-content v-else-if="total === 0">
                <!-- กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...  -->
                {{ $t("myinboxPage.dowload.downloadpreparing") }}
                <v-list-item-title>{{
                  namedowload | subStr
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else-if="total === 99">
                {{ $t("myinboxPage.dowload.dowloading_print") }}
              </v-list-item-content>
              <v-list-item-content v-else>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <!-- {{ count_loadingfile }}/{{ count_allfile }} -->
                <!-- {{ count_allfile > 1 ? $t("files") : $t("file") }} ) -->
                <!-- {{statusloading}}   ( {{count_loadingfile}}/{{count_allfile}})   -->
                <v-list-item-title>{{
                  namedowload | subStr
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="percent === 100" color="green"
                  >mdi-check-bold</v-icon
                >
                <div v-else class="text-center pt-1">
                  <a style="color:white;">{{ total + "%" }}</a>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-progress-linear
          v-if="btsdownload === true"
          indeterminate
          color="green"
          height="8"
        ></v-progress-linear>
      </v-card>
    </v-snackbar>
  </div>
  <div class="text-center ma-2" v-else>
    <v-snackbar
      v-model="snackbar"
      bottom
      class="v-snack__content"
      :timeout="-1"
    >
      <v-card color="transparent" class="pa-0">
        <!-- <v-list
          color="transparent"
          dark
          v-if="count_loadingfile === undefined && count_allfile === undefined"
        >
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content v-if="percent === 100">
              {{ $t("myinboxPage.dowload.dowloadsuccess") }}
              <v-list-item-title
                v-if="
                  namedowload === '' ||
                    namedowload === null ||
                    namedowload === undefined
                "
                >{{ date }}</v-list-item-title
              >
              <v-list-item-title
                v-else-if="date === '' || date === null || date === undefined"
                >{{ namedowload }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-content v-else>
              {{ $t("myinboxPage.dowload.dowloading") }}
              <v-list-item-title
                v-if="
                  namedowload === '' ||
                    namedowload === null ||
                    namedowload === undefined
                "
                >{{ date }}</v-list-item-title
              >
              <v-list-item-title
                v-else-if="date === '' || date === null || date === undefined"
                >{{ namedowload }}</v-list-item-title
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-if="percent === 100" color="green"
                >mdi-check-bold</v-icon
              >
              <div v-else class="text-center pt-1">
                <a style="color:white;">{{ percent + "%" }}</a>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list> -->
        <v-list color="transparent" dark>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content v-if="total === 100">
              {{ $t("myinboxPage.dowload.dowloadsuccess") }}
              <!-- <v-list-item-title>{{ namedowload }}</v-list-item-title> -->
            </v-list-item-content>
            <v-list-item-content v-else-if="total === 0">
              <!-- กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่... -->
              {{ $t("myinboxPage.dowload.downloadpreparing") }}

              <v-list-item-title>{{ namedowload | subStr }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else-if="total === 99">
              {{ $t("myinboxPage.dowload.dowloading_print") }}
            </v-list-item-content>
            <v-list-item-content v-else>
              {{ $t("myinboxPage.dowload.dowloading") }}
              <!-- {{ count_loadingfile }}/{{ count_allfile }}
              {{ count_allfile > 1 ? $t("files") : $t("file") }} ) -->
              <!-- {{statusloading}}   ( {{count_loadingfile}}/{{count_allfile}}) -->
              <v-list-item-title>{{ namedowload | subStr }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-if="total === 100" color="green">mdi-check-bold</v-icon>
              <div v-else class="text-center pt-1">
                <a style="color:white;">{{ total + "%" }}</a>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-progress-linear
          v-if="btsdownload === true"
          indeterminate
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-snackbar>
  </div>
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import format from "date-fns/format";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dialogLoginssoVue from "../optional/dialog-loginsso.vue";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios, { CancelToken } from 'axios';
import { log } from 'console';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      namedowload:'',
      timeout: 50000,
      count_loadingfile:0,
      count_allfile:0,
      percent:0,
      percent_load:0,
      btsdownload:false,
      total:0,
      calculate_total: 0,
      total_allfile:0,
      source: "",
      countCancel: 0,
      procesing: false,
      listerror:[]
    };
  },
  props: ["show", "data"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    snackbar: {
      get() {
        if (this.show === true && this.procesing === false) {
          this.fn_multidownload();
          this.procesing = true;
        } else if (this.show === false && this.source !== "") {
          this.countCancel = 1;
          console.log("canceltokenq", this.source);
          this.source.cancel();
        } else {
          setTimeout(() => {
            this.clearall_data()
            this.$emit("closedialog");
          }, 500)
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.procesing = false
          this.$emit("closedialog");
        }
      },
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 50) return "..." + string.substring(string.length - 50, string.length) ;
        else return string;
      }
    },
  },
  methods: {
    async fn_multidownload() {
      this.listerror = [];
      this.percent_load = 0;
      this.total_allfile = 0;
      this.countCancel = 0;
      this.total = 0;
      this.checkdownload_finish = false;
      let filecandownload = [];
      let file_id = [];
      let folder_id = [];
      console.log("this.data", this.data);

      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].file_status !== "M") {
          if (
            this.data[i].file_permission_2 !== "01" &&
            this.data[i].status_lock_data === "N"
          ) {
            if (this.data[i].file_type === "folder") {
              folder_id.push(this.data[i].file_id);
            } else {
              file_id.push(this.data[i].file_id);
            }
            filecandownload.push(this.data[i]);
          }
        }
      }
      console.log("filecandownload", filecandownload);
      console.log("file_id", file_id);

    //   this.newlist = [];

    //   for (let i = 0; i < this.filecandownload.length; i++) {
    //     let datadowload = {};
    //     datadowload["fileid"] = this.filecandownload[i].file_id;
    //     datadowload["name"] = this.filecandownload[i].file_name;
    //     datadowload["value"] = 0;
    //     this.newlist.push(datadowload);
    //   }

    //   for (let i = 0; i < this.filecandownload.length; i++) {
    //     this.dataIndex = this.newlist.findIndex(
    //       (obj) => obj.name === this.filecandownload[i].file_name
    //     );
    //   }
    //   console.log("this.dataIndex", this.dataIndex);
    //   this.opensnackbar = true;
    //   this.new_list = this.newlist;

      let payload = {
        account_id: this.dataAccountId,
        list_file: file_id,
        list_folder: folder_id,
      };
      let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        "/api/request/download_file_list";

    //   this.new_list = this.newlist;
    let auth = await gbfGenerate.generateToken();
      this.axios
        .post(url, payload, {headers: { Authorization: auth.code }})
        .then((response) => {
          console.log("fn_loop_downloading",response.data);
          if (response.data.status === "OK") {
            // response.data.all_file.push({
            //     file_id: "8ba3cc2dc065657e6b7c2a869c200000",
            //     full_path: "Mainfolder/TEST_MOVE_TEST/A2",
            //     location_file: "mapdrive/folder_downloaded/downloading/20b2030ebb3f774e1dce2a1b0561c68b20230517150000",
            //     path: "TEST_MOVE_TEST/A2"
            // })
            this.total_allfile = response.data.all_file.length
            this.fn_loop_downloading(
              response.data.all_file,
              response.data.zip_name,
              response.data.url
            );
          } else {
            this.btsdownload = false;
            this.snackbar = false
            // this.opensnackbar = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด ",
            });
          }
        })
        .catch((error) => {
          this.btsdownload = false;
          // this.opensnackbar = false;
          this.snackbar = false
          if (this.countCancel === 0) {
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด ",
            });
          }
          console.log("err", error);
        });
    },
    async fn_loop_downloading(all_file, zip_name, url_link) {
      this.check_downloading = true;
      console.log("all_file", all_file);
      console.log("zip_name", zip_name);
      console.log("url_link", url_link);

      this.total = 0;
      if (all_file.length === 0) {
        // this.newlist[this.dataIndex]["value"] = 100;
        this.total = 100;
        console.log(url_link);
        window.location.assign(url_link);
        // this.opensnackbar = false;
      } else {
        console.log("all_file", all_file);
        this.count_loadingfile = all_file.length;
        this.source = CancelToken.source()
        console.log("this.source", this.source.token);
        for (let i = 0; i < all_file.length; i++) {
            // (1 / all_file.length) * 100
            console.log("all_file.length", (1 / all_file.length) * 100, Math.ceil(this.total));
            this.namedowload = all_file[i].full_path;
            console.log(this.namedowload);
            // this.percent_load = this.total;
          let res = await this.downloading_file(
            all_file[i]["file_id"],
            all_file[i]["path"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            all_file[i],
            this.source.token
          );
          // let res = await this.downloading_file(
          //   url_link,
          //   zip_name,
          // );
        //   console.log(res);
        //   if (this.newlist[this.dataIndex]["value"] > 99) {
        //     this.newlist[this.dataIndex]["value"] = 99;
        //   } else {
        //     this.newlist[this.dataIndex]["value"] = Math.round(total);
        //   }
        //   this.newpercen = this.newlist[this.dataIndex]["value"];
        //   this.namefile = this.newlist[this.dataIndex]["name"];
        //   console.log(this.percentCompleted);
          console.log(i, all_file.length - 1);

          if (i === all_file.length - 1) {
            if(this.listerror.length === 0 ){
              this.countCancel = 0;
              this.total = 99;
              this.source = '';
              // this.check_downloading = false;
              // this.check_downlaod_step3 = true;
              this.download_finish(url_link, zip_name, this.source.token);
            }else{
              let y ;
              for ( y = 0; y < this.listerror.length; y++) {
                let res = await this.downloading_file_again(
                  this.listerror[y]["file_id"],
                  this.listerror[y]["path"],
                  this.listerror[y]["location_file"],
                  zip_name,
                  this.listerror[y]["path"],
                  this.listerror[y],
                  this.source.token
                );

                }
                if (y === this.listerror.length - 1) {
                  this.total = 99;
                  this.download_finish(url_link, zip_name, this.source.token);
                }
            }
            }
          }
        }
    },
    async downloading_file(file_id, path, location_file, zip_name, all_file, file, token) {
      // let CancelToken = this.axios.CancelToken;
      // this.source = new CancelToken.source();
        let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/downloading_file?file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file +
        "&account_id=" + this.dataAccountId;
      console.log(url);
       await this.axios
          .get(url)
          .then((response) => {
            console.log("response",response);
            if (response.data.status === "OK") {
              if(this.total_allfile > 100){
                this.calculate_total += (100 / this.total_allfile)
                this.total = Math.floor(this.calculate_total)
              }else {
                this.total += Math.floor(100/this.total_allfile);
              }
            } else {
              this.listerror.push(file);
              console.log("this.listerror",this.listerror);
            }
          })
          .catch((error) => {
            // if (this.countCancel === 0) {
              // this.source.cancel();
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
              console.log("err", error);
            // }
          });


    // //   let objIndex = this.filecandownload.findIndex(
    // //     (obj) => obj.file_id === file_id
    // //   );
    // //   if (objIndex == -1) {
    // //     objIndex = this.filecandownload.findIndex(
    // //       (obj) => obj.file_name === path
    // //     );
    // //   }
    // //   console.log("objIndex", objIndex);
    //   let url =
    //     process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
    //     "/api/downloading_file?file_id=" +
    //     file_id +
    //     "&location_file=" +
    //     location_file +
    //     "&zip_name=" +
    //     zip_name +
    //     "&path=" +
    //     all_file;
    //   console.log(url);

    //   let result = await this.axios({
    //     method: "get",
    //     url: url,
    //     // onDownloadProgress: (progressEvent) => {
    //     //     let progresspercent = Math.round(
    //     //           (progressEvent.loaded * 100) / progressEvent.total
    //     //     );
    //     // onDownloadProgress: (e) => {
    //     //   console.log((e.loaded / e.total) * 100 >= 95);
    //     //   if ((e.loaded / e.total) * 100 >= 99) {
    //     //     this.filecandownload[objIndex]["file_value"] = 100;
    //     //   } else {
    //     //     this.filecandownload[objIndex]["file_value"] = parseInt(
    //     //       (e.loaded / e.total) * 100
    //     //     );
    //     //   }
    //     // },
    //   });
    // //   return new Promise((resolve, reject) => {
    // //     try {
    // //       console.log("result", result);
    // //       if (result.data.status === "OK") {
    // //         this.filecandownload[objIndex]["file_status"] = this.$t(
    // //           "checkmultidownload.downloadsuccess"
    // //         );
    // //         this.filecandownload[objIndex]["file_value"] = 100;
    // //         setTimeout(() => {
    // //           resolve({ status: "delete success", msg: "OK", code: "" });
    // //         }, 500);
    // //       } else {
    // //         this.filecandownload[objIndex]["file_status"] = this.$t(
    // //           "checkmultidownload.downloadnotsuccess"
    // //         );
    // //         this.filecandownload[objIndex]["file_value"] = 500;
    // //         setTimeout(() => {
    // //           resolve({ status: "delete denied", msg: ": ", code: "" });
    // //         }, 500);
    // //       }
    // //     } catch (err) {
    // //       console.log("err", err);
    // //       setTimeout(() => {
    // //         resolve({ status: "delete denied", msg: ": " + err, code: "" });
    // //       }, 500);
    // //       this.filecandownload[objIndex]["file_status"] = this.$t(
    // //         "checkmultidownload.downloadnotsuccess"
    // //       );
    // //       this.filecandownload[objIndex]["file_value"] = 500;
    // //     }
    // //   });
    },
    async downloading_file_again(file_id, path, location_file, zip_name, all_file,file, token) {
        let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/downloading_file?file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file +
        "&account_id=" + this.dataAccountId;
      console.log(url);
       await this.axios
          .get(url)
          .then((response) => {
            console.log("response",response);
            if (response.data.status === "OK") {
                this.total += Math.floor(100/this.total_allfile);
            } else {
              console.log("เข้า doawnload อีกครั้งแต่ error");
              this.source.cancel();
              this.snackbar = false;
              Toast.fire({
                icon: "error",
                title: response.data.filename + " " +":" + " " + response.data.errorMessage ,
              });
            }
          })
          .catch((error) => {
            if (this.countCancel === 0) {
              // this.source.cancel();
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
              console.log("err", error);
            }
          });
    },
    download_finish(url_folder, zip_name, token) {
    //   this.checkdownload_finish = false;
      console.log("url_folder", url_folder);
      console.log("zip_name", zip_name);
      let url = url_folder;
      console.log(url);
      console.log(this.countCancel);
      if (this.countCancel === 0) {
        this.axios
          .get(url, {
            withCredentials: false,
            responseType: "arraybuffer",
            cancelToken: token,
          })
          .then((response) => {
            console.log("response",response);
            console.log("testtt", response.statusText);
            console.log("response", response);
            console.log("response.data", response.data);
            if (response.status === 200) {
              console.log("เข้า 100");
              this.total = 100;
              // this.check_downlaod_step3 = false;
              // this.checkdownload_finish = true;
              // this.newlist[this.dataIndex]["value"] = 100;

              // console.log(url_folder);
              // window.location.assign(url_folder);
              let headers = response.headers;
              let blob = new Blob([response.data], {
                type: headers["content-type"],
              });
              console.log("blob", blob);
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = format(new Date(), "yyyyMMddHHmmss");
              link.click();
              setTimeout(() => {
                  this.snackbar = false;
              }, 3000)
              this.source = "";
            }else{
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
            }
          })
          .catch((error) => {
            if (this.countCancel === 0) {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
              console.log("err", error);
            }
          });
      }
    },

    clearall_data () {
      this.namedowload =''
      this.timeout = 50000,
      this.count_loadingfile = 0
      this.count_allfile = 0
      this.calculate_total = 0
      this.percent = 0
      this.percent_load = 0
      this.btsdownload = false
      this.total = 0
      this.total_allfile = 0
      this.sourc = ""
      this.countCancel = 0
      this.procesing = false
      this.listerror = []
    },
  }

}
</script>
<style scoped>
.v-application--is-ltr .v-snack__action {
    margin-right: 0px;
}
</style>
